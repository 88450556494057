<template>
  <b-container>
    <b-breadcrumb v-if="!unowned && !$store.state.pageload">
      <b-breadcrumb-item href="#home">
        <router-link :to="{ name: 'root' }">Home </router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item href="#">
        <router-link
          v-if="this.api.network_id"
          :to="{ name: 'net_home', params: { networkId: this.api.network_id } }"
          >{{ this.network_name }}
        </router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item>
        Device
      </b-breadcrumb-item>
    </b-breadcrumb>
    <div class="b-main-content">
      <b-modal
        id="modal-2"
        title="Are you sure you want to delete this device? "
        hide-footer
      >
        <p>
          Note that a deleted device can be claimed by another account if they
          have the device ID, which may be found on the device.
        </p>
        <b-button class="float-right" variant="danger" @click="deleteDevice"
          >Delete device from this network</b-button
        >
      </b-modal>

      <b-card v-if="!$store.state.pageload && unowned">
        <template #header>
          <h3 class="mb-0">
            This device was not found in your account
          </h3>
        </template>

        <p v-if="registered">This device is registered to another account.</p>
        <p v-if="!registered">
          This device is not registered to any account. You can register it on
          one of your <a :href="'/'">networks</a>.
        </p>
        <p>
          You can view the public information on this device at its Device URL:
          <br class="mt-4" />
          <a
            :href="'https://blecon.dev/' + this.$route.params.devId"
            target="_blank"
            >https://blecon.dev/{{ this.$route.params.devId }}</a
          >
        </p>
      </b-card>

      <b-card-group deck class="mt-4" v-if="!$store.state.pageload && !unowned">
        <b-card>
          <template #header>
            <h3 class="mb-0">
              Device Summary
            </h3>
          </template>

          <b-table-simple bordered responsive>
            <b-tbody>
              <b-tr>
                <b-th>Device ID</b-th>
                <b-td
                  ><code>{{ this.api.device_id }}</code></b-td
                >
              </b-tr>
              <b-tr>
                <b-th>Network</b-th>
                <b-td>
                  <router-link
                    v-if="this.api.network_id"
                    :to="{
                      name: 'net_home',
                      params: { networkId: this.api.network_id }
                    }"
                    >{{ this.network_name }}</router-link
                  >
                </b-td>
              </b-tr>

              <b-tr>
                <b-th>Device Registered</b-th>
                <b-td>{{ this.api.created }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Device Updated</b-th>
                <b-td>{{ this.api.last_updated || "Unknown" }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <b-button
            variant="danger"
            :title="
              $store.state.accountLevel > 20 ? 'Insufficent permissions' : ''
            "
            :disabled="$store.state.accountLevel > 20"
            v-b-modal.modal-2
            >Delete device</b-button
          >
        </b-card>

        <b-card>
          <template #header>
            <h3 class="mb-0">
              Device Stats Summary
            </h3>
          </template>
          <b-table-simple bordered responsive>
            <b-tbody>
              <b-tr>
                <b-th>First Connected</b-th>
                <b-td>{{ this.api.first_connected || "Never connected" }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Requests in 24h</b-th>
                <b-td>{{ this.requests_today || "No requests" }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Bytes up in 24h</b-th>
                <b-td>{{ this.bytes_up_today || "No bytes" }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Bytes down in 24h</b-th>
                <b-td>{{ this.bytes_down_today || "No bytes" }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Connections in 24h</b-th>
                <b-td>{{ this.connections_today || "No connections" }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Last Request</b-th>
                <b-td>{{ this.api.last_request || "No requests" }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Last Spotted</b-th>
                <b-td>{{ this.api.last_seen || "Never seen" }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-card-group>
      <b-card-group deck class="mt-4" v-if="!$store.state.pageload && !unowned">
        <b-card>
          <template #header>
            <h3 class="mb-0">
              Device Connections and Requests
            </h3>
          </template>
          <b-card-text>
            <MyChart
              :stats="stats"
              :mode="'requests'"
              v-if="stats.length > 0"
            />
          </b-card-text>
        </b-card>

        <b-card>
          <template #header>
            <h3 class="mb-0">
              Device Data Usage
            </h3>
          </template>
          <b-card-text>
            <MyChart :stats="stats" :mode="'bytes'" v-if="stats.length > 0" />
          </b-card-text>
        </b-card>
      </b-card-group>
      <b-card-group deck class="mt-4" v-if="!$store.state.pageload && !unowned">
        <b-card>
          <template #header>
            <h3 class="mb-0">
              Device Location
            </h3>
          </template>
          <b-card-text>
            <div v-if="api.last_location">
              <GmapMap
                :center="{
                  lat: parseFloat(api.last_location.split(',')[0]),
                  lng: parseFloat(api.last_location.split(',')[1])
                }"
                :zoom="12"
                style="width: 100%; height: 400px;"
              >
                <GmapMarker
                  :position="{
                    lat: parseFloat(api.last_location.split(',')[0]),
                    lng: parseFloat(api.last_location.split(',')[1])
                  }"
                ></GmapMarker>
              </GmapMap>
              <p class="mt-3">
                Last known location:
                <code>{{ api.last_location || "Unknown" }}</code
                ><br />
                Updated {{ api.location_age || "at an unknown time" }} ago
              </p>
            </div>
            <div v-else>
              No location data available. The device may not have made any
              requests yet.
            </div>
          </b-card-text>
        </b-card>
      </b-card-group>

      <EventsExplorer
        :show_header="false"
        :device_id_filter="this.$route.params.devId"
        v-if="!unowned && !$store.state.pageload"
      ></EventsExplorer>
    </div>
  </b-container>
</template>

<script>
//import shared from '@/components/Shared'
import router from "@/router";
import store from "@/store";
import {
  format,
  formatDistance,
  formatRelative,
  subDays,
  intervalToDuration,
  formatDuration
} from "date-fns";
import axios from "axios";
import MyChart from "../components/Chart.vue";
import EventsExplorer from "../components/EventsExplorer.vue";

export default {
  data() {
    return {
      unowned: false,
      registered: false,
      requests_today: 0,
      bytes_up_today: 0,
      bytes_down_today: 0,
      connections_today: 0,
      api: {},
      stats: [],
      network_name: "",
      options: []
    };
  },
  components: {
    MyChart: MyChart,
    EventsExplorer: EventsExplorer
  },

  created() {
    this.loadData();
  },
  mounted() {},
  methods: {
    async loadData() {
      store.commit("pageLoad");
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());

      var deviceRequest = this.$axios.get(
        "/v1/accounts/" +
          store.state.accountid +
          "/devices/" +
          this.$route.params.devId
      );
      var appsRequest = this.$axios.get(
        "/v1/accounts/" + store.state.accountid + "/networks?pagesize=1000"
      );
      var statsRequest = this.$axios.get(
        "/v1/accounts/" +
          store.state.accountid +
          "/stats?type=device&type_key=" +
          this.$route.params.devId
      );

      Promise.all([deviceRequest, appsRequest, statsRequest])
        .then(values => {
          const device_response = values[0];
          const apps_response = values[1];
          const stats_response = values[2];

          this.stats = stats_response.data.stats;

          // look at the stats and calculate some totals for just the last 24 hours
          var last24 = this.stats.slice(-24);

          this.requests_today = last24.reduce(
            (acc, item) => acc + item.metrics.requests,
            0
          );
          this.bytes_up_today = last24.reduce(
            (acc, item) => acc + item.metrics.bytes_up,
            0
          );
          this.bytes_down_today = last24.reduce(
            (acc, item) => acc + item.metrics.bytes_down,
            0
          );
          this.connections_today = last24.reduce(
            (acc, item) => acc + item.metrics.connections,
            0
          );

          this.api = device_response.data;

          if (this.api.location_age) {
            const duration = intervalToDuration({
              start: 0,
              end: parseInt(this.api.location_age) * 1000
            });
            this.api.location_age = formatDuration(duration, {
              format: ["years", "months", "days", "hours", "minutes"]
            });
          }
          if (this.api.last_seen) {
            var sdate = new Date(parseInt(this.api.last_seen) * 1000);
            this.api.last_seen = formatDistance(sdate, new Date(), {
              addSuffix: true
            });
          }
          var date = new Date(parseInt(this.api.created) * 1000);
          this.api.created = formatDistance(date, new Date(), {
            addSuffix: true
          });

          if (this.api.last_request) {
            var last_request = new Date(parseInt(this.api.last_request) * 1000);
            this.api.last_request = formatDistance(last_request, new Date(), {
              addSuffix: true
            });
          }

          if (this.api.last_updated) {
            var last_updated = new Date(parseInt(this.api.last_updated) * 1000);
            this.api.last_updated = formatDistance(last_updated, new Date(), {
              addSuffix: true
            });
          }

          if (this.api.first_connected) {
            var first_connected = new Date(
              parseInt(this.api.first_connected) * 1000
            );
            this.api.first_connected = formatDistance(
              first_connected,
              new Date(),
              {
                addSuffix: true
              }
            );
          }

          //set name of configured app instead of id

          // look in apps data for matching network_id
          var network_name = apps_response.data.networks.find(
            app => app.network_id === device_response.data.network_id
          );

          if (network_name) {
            this.network_name = network_name.name;
          }
          if (this.$route.query.action == "default_landing_page") {
            this.$notify({
              title:
                "Loaded console default device landing page. You can change this behaviour in your network settings.",
              type: "success"
            });
          }
          store.commit("pageLoadFinished");
        })
        .catch(err => {
          this.$log.error(err);
          // if not 404, then we have a problem
          if (err.response == undefined) {
            this.$bvToast.toast(err.response, {
              title: "Error loading device",
              variant: "danger",
              toaster: "b-toaster-bottom-left",
              solid: true
            });
            return;
          }
          if (err.response.status != 404) {
            this.$bvToast.toast(err.response.status, {
              title: "Error loading device",
              variant: "danger",
              toaster: "b-toaster-bottom-left",
              solid: true
            });
            return;
          }

          //get public info
          this.$axios
            .get("/v1/public/devices/" + this.$route.params.devId)
            .then(response => {
              if (response.data.registered) {
                this.unowned = true;
                this.registered = true;
              } else {
                this.unowned = true;
                this.registered = false;
              }
              store.commit("pageLoadFinished");
            })
            .catch(err => {
              this.$log.error(err);
              store.commit("pageLoadFinished");
              this.$notify({
                title: "Error loading device",
                type: "danger"
              });
            });
        });
    },

    async deleteDevice() {
      this.$root.$emit("bv::hide::modal", "modal-1");
      this.$root.$emit("bv::hide::modal", "modal-2");
      this.$axios
        .delete(
          "/v1/accounts/" +
            store.state.accountid +
            "/devices/" +
            this.$route.params.devId
        )
        .then(() => {
          this.$notify({
            title: "Device Deleted",
            type: "success"
          });
          router.push({
            name: "net_home",
            params: {
              networkId: this.api.network_id
            }
          });
        });
    },
    onReset(event) {
      this.$root.$emit("bv::hide::modal", "modal-1");

      event.preventDefault();
      // Reset our form values to what we last got from the API
      Object.assign(this.form, this.api);

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>
