import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import axios from "axios";
import VueLogger from "vuejs-logger";
import Notifications from "vue-notification";
import * as VueGoogleMaps from "vue2-google-maps";

// [TODO] Can this be an external module?
import { Auth0Plugin } from "@/auth";

import App from "@/App";
import router from "@/router";
import store from "@/store";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import * as Sentry from "@sentry/vue";

Sentry.init({
  Vue,
  dsn:
    "https://2f6b70d590e4625c3999bfbbfedfa358@o4504050183110656.ingest.sentry.io/4506750921867264",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.VUE_APP_TITLE,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.blecon.net/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

Vue.use(Notifications, {
  duration: 35000 // Set the default duration for all notifications (in milliseconds)
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyABvRIYl8mReU5FKVrno_ONaSP3Q_5jBVQ"
  }
});

Vue.use(BootstrapVue);
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_CLIENTID,
  audience: process.env.VUE_APP_AUDIENCE,
  cacheLocation: "localstorage",
  onRedirectCallback: appState => {
    router
      .push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      )
      .catch();
  }
});

const isProduction = process.env.NODE_ENV === "production";
const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};
Vue.use(VueLogger, options);

const axiosConfig = {
  baseURL: process.env.VUE_APP_API,
  timeout: 30000
};
Vue.prototype.$axios = axios.create(axiosConfig);

export const bus = new Vue();

const originalEmit = bus.$emit;
bus.$emit = function(eventName, ...args) {
  // Log the event name and arguments
  this.$log.debug(`Event emitted: ${eventName}`, args);

  // Capture the caller (stack trace)
  const stack = new Error().stack;
  this.$log.debug(`Event "${eventName}" was emitted by:`, stack);

  // Call the original $emit method
  return originalEmit.call(this, eventName, ...args);
};

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
