<template>
  <div>
    <EventsExplorer></EventsExplorer>
  </div>
</template>

<script>
import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";
hljs.registerLanguage("json", json);

import "highlight.js/styles/default.css"; // or choose another style
import EventsExplorer from "@/components/EventsExplorer.vue";

import {
  format,
  formatDistance,
  formatRelative,
  lastDayOfDecade,
  subDays
} from "date-fns";
import store from "@/store";

export default {
  components: {
    EventsExplorer
  },

  data() {
    return {
      time1: new Date().toISOString().split("T")[0],
      results: [],
      loads: 0,
      timer: undefined,
      selectedEvent: null,
      loopEnabled: true,
      fields: [
        {
          key: "timestamp",
          label: "Time (UTC)",
          sortable: true,
          formatter: "formatDate"
        },
        { key: "device_id", label: "Device", sortable: false },

        { key: "event_type", label: "Event", sortable: false },
        { key: "text", label: "Details", sortable: false }
      ],

      filters: {
        device_id: null
      },
      dateFilters: [
        {
          field: "created",
          start: null,
          end: null
        },
        {
          field: "last_seen",
          start: null,
          end: null
        },
        {
          field: "last_updated",
          start: null,
          end: null
        }
      ],

      currentPage: 1,
      total: 0,
      perPage: 30,
      isLoading: false,
      cursor: undefined,
      deviceCount: 0,
      sort: { field: "timestamp", order: "desc" }
    };
  },
  computed: {
    highlightedJson() {
      if (!this.selectedEvent) return "";
      const jsonString = JSON.parse(this.selectedEvent);
      const parsedJson = JSON.stringify(jsonString, null, 2);

      return hljs.highlight(parsedJson, { language: "json" }).value;
    }
  },
  methods: {
    startTimeout() {
      this.loopEnabled = true;
    },
    stopTimeout() {
      this.loopEnabled = false;
    },

    resetFilters() {
      this.filters = {
        device_id: null,
        event_type: null
      };
      this.dateFilters = [
        {
          field: "created",
          start: null,
          end: null
        },
        {
          field: "last_seen",
          start: null,
          end: null
        },
        {
          field: "last_updated",
          start: null,
          end: null
        }
      ];
      //this.getResults();
    },

    selectEvent(event) {
      this.selectedEvent = event.full_event;
      if (this.selectedEvent != undefined) {
        document.body.style.overflow = "hidden"; // Disable scrolling

        this.$root.$emit("bv::show::modal", "modal-fullevent");
      }
    },
    closeModal() {
      this.selectedEvent = null;
      document.body.style.overflow = "auto"; // Enable scrolling
    },

    formatDate(value) {
      const formattedDate = format(new Date(value), "yyyy-MM-dd HH:mm:ss");
      return formattedDate;
    },

    async getResults() {
      this.isLoading = true;
      var accessToken = await this.$auth.getTokenSilently();
      store.commit("spin");

      const mustClauses = [];
      mustClauses.push({
        field: "network_id",
        value: this.$route.params.networkId
      });

      if (this.filters.device_id) {
        mustClauses.push({ field: "device_id", value: this.filters.device_id });
      }

      if (this.filters.event_type) {
        mustClauses.push({
          field: "event_type",
          value: this.filters.event_type
        });
      }

      const searchBody = {
        sort: this.sort,
        pagination: {
          page_size: this.perPage,
          page: this.currentPage
        },
        fields: mustClauses,
        date_ranges: this.dateFilters
      };
      this.$axios
        .post(
          "/v1/accounts/" + store.state.accountid + "/log-explorer",
          searchBody
        )
        .then(response => {
          this.results = response.data.results;
          this.total = response.data.total;
          this.currentPage = response.data.page;
          store.commit("nospin");
        });
      this.loads++;

      this.isLoading = false;
    },

    onSortChanged({ sortBy, sortDesc }) {
      var sortType = "asc";
      if (sortDesc == true) sortType = "desc";
      this.sort = { field: sortBy, order: sortType };
      this.getResults();
    }
  },
  mounted() {
    // Load initial data when the component is mounted

    // if query param for device_id
    if (this.$route.query.ex_device_id) {
      this.filters.device_id = this.$route.query.ex_device_id;
    }
    this.getResults();
  },

  beforeDestroy() {
    // Component is hidden; clear the timeout
    this.stopTimeout();
  },
  activated() {
    // Component is visible again; restart the timeout if needed
    //this.startTimeout();
  },

  watch: {
    // Load data whenever the page changes
    currentPage() {
      this.getResults();
    }
  }
};
</script>
