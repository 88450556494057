import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import NotFound from "@/pages/NotFoundPage.vue";
import CreateAccount from "@/pages/CreateAccount.vue";
import RequestAccount from "@/pages/RequestAccount.vue";

import Networks from "@/pages/Networks.vue";
import NetBase from "@/pages/Network/NetBase.vue";
import NetHome from "@/pages/Network/NetHome.vue";
import NetDevices from "@/pages/Network/NetDevices.vue";
import NetRoutes from "@/pages/Network/NetRoutes.vue";

import NetSettings from "@/pages/Network/NetSettings.vue";
import NetIntegrations from "@/pages/Network/NetIntegrations.vue";
import NetStats from "@/pages/Network/NetStats.vue";

import Devices from "@/pages/Devices.vue";
import ViewDevice from "@/pages/ViewDevice.vue";
import ViewAccount from "@/pages/Account/ViewAccount.vue";
import APITokens from "@/pages/Account/APITokens.vue";
import AccountMembers from "@/pages/Account/AccountMembers.vue";
import Invites from "@/pages/Account/Invites.vue";
import Logs from "@/pages/Account/Logs.vue";

import GlobalForwarders from "@/pages/GlobalForwarders.vue";
import Hotspots from "@/pages/Hotspots.vue";
import HotspotAdd from "@/pages/HotspotAdd.vue";

import AcceptInvite from "@/pages/Account/AcceptInvite.vue";
import Register from "@/pages/Register.vue";
import Connect from "@/pages/Connect.vue";
import UserProfile from "@/pages/UserProfile/UserProfile.vue";

import { authGuard } from "../auth/authGuard";
import { GlobalHandlers } from "@sentry/vue";

const routes = [
  {
    path: "/",
    component: Networks,
    name: "root",
    beforeEnter: authGuard,
    meta: {
      title: "Home"
    }
  },
  {
    path: "/r/:deviceid",
    name: "register ",
    component: Register,
    beforeEnter: authGuard
  },
  {
    path: "/connect",
    name: "connect",
    component: Connect,
    beforeEnter: authGuard,
    meta: {
      title: "Connect Device"
    }
  },
  {
    path: "/profile",
    name: "userprofile",
    component: UserProfile,
    beforeEnter: authGuard,
    meta: {
      title: "User Profile"
    }
  },
  {
    path: "/accept_invite",
    name: "acceptinvite",
    component: AcceptInvite,
    beforeEnter: authGuard,
    meta: {
      title: "Accept Invite"
    }
  },
  {
    path: "/global_forwarders",
    name: "global_forwarders",
    component: GlobalForwarders,
    beforeEnter: authGuard,
    meta: {
      title: "Global Forwarders"
    }
  },
  {
    path: "/hotspots",
    name: "hotspots",
    component: Hotspots,
    beforeEnter: authGuard,
    meta: {
      title: "Hotspots"
    }
  },
  {
    path: "/hotspots/link",
    name: "hotspot_link",
    component: HotspotAdd,
    beforeEnter: authGuard,
    meta: {
      title: "Link Hotspot"
    }
  },
  {
    path: "/account",
    name: "accountsettings",
    component: ViewAccount,
    beforeEnter: authGuard,
    meta: {
      title: "Account Settings"
    },
    children: [
      {
        path: "",
        name: "account_members",
        component: AccountMembers,
        beforeEnter: authGuard,
        meta: {
          title: "Account Members"
        }
      },
      {
        path: "logs",
        name: "account_logs",
        component: Logs,
        beforeEnter: authGuard,
        meta: {
          title: "Account Logs"
        }
      },
      {
        path: "invites",
        name: "account_invites",
        component: Invites,
        beforeEnter: authGuard,
        meta: {
          title: "Account Invites"
        }
      },

      {
        path: "apitokens",
        name: "account_apitokens",
        component: APITokens,
        beforeEnter: authGuard,
        meta: {
          title: "Account API Tokens"
        }
      }
    ]
  },
  {
    path: "/networks/:networkId",
    component: NetBase,
    beforeEnter: authGuard,
    meta: {
      title: "Network"
    },
    children: [
      {
        path: "",
        name: "net_home",
        component: NetHome,
        beforeEnter: authGuard,
        meta: {
          title: "Network"
        }
      },
      {
        path: "integrations",
        name: "net_integrations",
        component: NetIntegrations,
        beforeEnter: authGuard,
        meta: {
          title: "Hosted Integrations"
        }
      },
      {
        path: "settings",
        name: "net_settings",
        component: NetSettings,
        beforeEnter: authGuard,
        meta: {
          title: "Network Settings"
        }
      },
      {
        path: "routes",
        name: "net_routes",
        component: NetRoutes,
        beforeEnter: authGuard,
        meta: {
          title: "Network Routes"
        }
      },
      {
        path: "devices",
        name: "net_devices",
        component: NetDevices,
        beforeEnter: authGuard,
        meta: {
          title: "Network Devices"
        }
      },
      {
        path: "stats",
        name: "net_stats",
        component: NetStats,
        beforeEnter: authGuard,
        meta: {
          title: "Network Statistics"
        }
      }
    ]
  },
  {
    path: "/devices",
    name: "devices",
    component: Devices,
    beforeEnter: authGuard,
    meta: {
      title: "Devices"
    }
  },
  {
    path: "/devices/:devId",
    name: "viewdevice",
    component: ViewDevice,
    beforeEnter: authGuard,
    meta: {
      title: "View Device"
    }
  },
  {
    path: "/create_account",
    name: "create_account",
    component: CreateAccount,
    beforeEnter: authGuard,
    meta: {
      title: "Create Account"
    }
  },
  {
    path: "/request_account",
    name: "request_account",
    component: RequestAccount,
    beforeEnter: authGuard,
    meta: {
      title: "Request Account"
    }
  },
  { path: "*", component: NotFound, beforeEnter: authGuard }
];

const router = new VueRouter({
  routes: routes,
  linkActiveClass: "active",
  mode: "history"
});

router.onError(err => {
  // Ignore the 'Redirected when going from' error
  if (err.message.includes("Redirected when going from")) {
    // Silently ignore this specific error
    return;
  }
  if (
    err.message.includes("Avoided redundant navigation to current location")
  ) {
    // Silently ignore this specific error
    return;
  }
  if (err.message.includes("Navigation cancelled from")) {
    // Silently ignore this specific error
    return;
  }
  if (err.message.includes("NavigationDuplicated")) {
    // Silently ignore this specific error
    return;
  }
  // For other errors, log or handle them
  this.$log.error(err);
});

export default router;
