<template>
  <div>
    <!-- Canvas element for Chart.js -->
    <canvas ref="myChart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";

export default {
  name: "MyChart",

  props: {
    stats: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },

  mounted() {
    // Prepare labels (timestamps) and data (metrics.requests)
    const labels = this.stats.map(entry => entry.timestamp);
    const requestsData = this.stats.map(entry => entry.metrics.requests);
    const bytesUpData = this.stats.map(item => item.metrics.bytes_up);
    const bytesDownData = this.stats.map(item => item.metrics.bytes_down);
    const connTimeData = this.stats.map(item => item.metrics.connected_time);
    const connNumberData = this.stats.map(item => item.metrics.connections);
    const activeData = this.stats.map(item => item.metrics.active_devices);
    const registeredData = this.stats.map(
      item => item.metrics.registered_devices
    );
    const seenDevicesData = this.stats.map(item => item.metrics.seen_devices);

    // Create Chart.js instance
    const ctx = this.$refs.myChart.getContext("2d");

    var datasets = [];
    if (this.mode == "bytes") {
      datasets = [
        {
          label: "Bytes to Cloud",
          data: bytesUpData,
          backgroundColor: "rgba(255, 99, 132, 0.2)", // Light red fill
          borderColor: "rgba(255, 99, 132, 1)", // Red line
          borderWidth: 2,
          fill: false
        },
        {
          label: "Bytes to Device",
          data: bytesDownData,
          backgroundColor: "rgba(255, 206, 86, 0.2)", // Light yellow fill
          borderColor: "rgba(255, 206, 86, 1)", // Yellow line
          borderWidth: 2,
          fill: false
        }
      ];
    } else if (this.mode == "requests") {
      datasets = [
        {
          label: "Requests",
          data: requestsData,
          backgroundColor: "rgba(54, 162, 235, 0.2)", // Light-blue fill
          borderColor: "rgba(54, 162, 235, 1)", // Blue stroke
          borderWidth: 2,
          fill: false
        },
        {
          label: "Connected Time (sec)",
          data: connTimeData,
          backgroundColor: "rgba(75, 192, 192, 0.2)", // Light teal fill
          fill: false,
          borderWidth: 2
        },
        {
          label: "Connections",
          data: connNumberData,
          backgroundColor: "rgba(153, 102, 255, 0.2)", // Light purple fill
          borderColor: "rgba(153, 102, 255, 1)", // Purple line
          borderWidth: 2,
          fill: false
        }
      ];
    } else if (this.mode == "seen_devices") {
      datasets = [
        {
          label: "Seen Devices",
          data: seenDevicesData,
          backgroundColor: "rgba(54, 162, 235, 0.2)", // Light-blue fill
          borderColor: "rgba(54, 162, 235, 1)", // Blue stroke
          borderWidth: 2,
          fill: false
        },
        //registered
        {
          label: "Registered Devices",
          data: registeredData,
          backgroundColor: "rgba(255, 99, 132, 0.2)", // Light red fill
          borderColor: "rgba(255, 99, 132, 1)", // Red line
          borderWidth: 2,
          fill: false
        },
        //active
        {
          label: "Active Devices",
          data: activeData,
          backgroundColor: "rgba(255, 159, 64, 0.2)", // Light orange fill
          borderColor: "rgba(255, 159, 64, 1)", // Orange line
          borderWidth: 2,
          fill: false
        }
      ];
    }

    new Chart(ctx, {
      type: "line",
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            // If you want a time-based x-axis, enable time parsing:
            type: "time",
            time: {
              unit: "hour",
              displayFormats: {
                hour: "yyyy-MM-dd"
              }
            }
          },
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }
};
</script>

<style scoped>
/* Give the chart some height */
div {
  height: 400px;
}
</style>
