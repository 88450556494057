<template>
  <b-container>
    <b-breadcrumb>
      <b-breadcrumb-item href="#home">
        <router-link :to="{ name: 'root' }">Home </router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item href="#">{{ api.name }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-modal id="modal-qr" title="Launching the Blecon Scanner" hide-footer>
      <div v-if="isMobile">
        <b-button variant="primary" size="md" target="scanner" :href="callback"
          >Launch Blecon Scanner</b-button
        >
      </div>
      <div v-else>
        <qrcode-vue :value="callback" :size="180"></qrcode-vue>
        <p>
          <br />
          Scan with your mobile phone to start adding a device.
        </p>
        <p>
          <small>
            Builder Tip:
            <a
              href="https://developer.blecon.net/learn-blecon/blecon-networks/registering-devices/the-blecon-scanner"
              target="_docs"
              >Learn how to integrate the Blecon Scanner into your own
              product.</a
            >
          </small>
        </p>
      </div>
    </b-modal>

    <div>
      <b-card-group deck>
        <b-card header="Network" header-tag="header">
          <template #header>
            <h3 class="mb-0">
              Network Information
            </h3>
          </template>
          <h5>
            {{ this.api.name || "Not Set" }}
          </h5>
          <div class="text-muted">{{ $route.params.networkId }}</div>
          <div>Created: {{ this.api.created || "Not Set" }}</div>
          <div>Updated: {{ this.api.last_updated || "Not Set" }}</div>
          <div>Registered Devices: {{ this.api.device_count || "0" }}</div>
        </b-card>
        <b-card header="Register Device" header-tag="header">
          <template #header>
            <h3 class="mb-0">
              Register Device
            </h3>
          </template>

          <b-form @submit="registerDevice">
            <b-form-group
              id="uuidInput"
              label="Device ID"
              label-for="uuidInputField"
            >
              <b-row>
                <b-col cols="11">
                  <b-input-group>
                    <b-form-input
                      width="100%"
                      class="form-control-sm"
                      id="uuidInputField"
                      v-model="$v.value.deviceID.$model"
                    ></b-form-input>

                    <b-button
                      variant="secondary"
                      size="sm"
                      @click="() => this.startScannerSession()"
                      >Use Scanner</b-button
                    >
                  </b-input-group>

                  <div class="form-error" v-if="$v.value.deviceID.$error">
                    Valid Device ID required
                  </div>
                </b-col>
              </b-row>
              <br />

              <b-button
                type="submit"
                variant="primary"
                :disabled="$v.value.deviceID.$error"
                >Register Device</b-button
              >

              <a
                target="docs"
                style="float: right"
                class="text-muted small mt-4"
                href="https://docs.blecon.net/learn-blecon/registering-devices"
                ><i class="bx bx-book-open"></i> Learn more about the scanner
                <br />and registering devices</a
              >
            </b-form-group>
          </b-form>
        </b-card>
      </b-card-group>
    </div>
    <b-nav tabs class="mt-3">
      <b-nav-item
        exact
        :active="this.$route.name == 'net_home'"
        :to="{
          name: 'net_home',
          params: { networkId: this.$route.params.networkId }
        }"
      >
        Events
      </b-nav-item>
      <b-nav-item
        :to="{
          name: 'net_stats',
          params: { networkId: this.$route.params.networkId }
        }"
      >
        Statistics
      </b-nav-item>
      <b-nav-item
        :to="{
          name: 'net_devices',
          params: { networkId: this.$route.params.networkId }
        }"
      >
        Devices
      </b-nav-item>

      <b-nav-item
        :to="{
          name: 'net_integrations',
          params: { networkId: this.$route.params.networkId }
        }"
        >Integrations</b-nav-item
      >
      <b-nav-item
        v-if="this.created_original < 1733409781"
        :to="{
          name: 'net_routes',
          params: { networkId: this.$route.params.networkId }
        }"
      >
        Event Routes
      </b-nav-item>

      <b-nav-item
        :to="{
          name: 'net_settings',
          params: { networkId: this.$route.params.networkId }
        }"
        >Network Settings</b-nav-item
      >
    </b-nav>

    <router-view :netinfo="api"></router-view>
  </b-container>
</template>

<script>
//import shared from '@/components/Shared'
import router from "@/router";
import store from "@/store";
import { bus } from "../../main";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import QrcodeVue from "qrcode.vue";

const uuidRegex = helpers.regex(
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
);
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import { v4 as uuidv4 } from "uuid";

export default {
  mounted() {
    if (this.$route.query.device_id) {
      // Set the value of 'name' from query param
      this.$v.value.deviceID.$model = this.$route.query.device_id;
    }
  },

  components: {
    QrcodeVue
  },

  data() {
    return {
      api: {},
      deviceID: "",
      callback: null,
      created_original: 0,
      scanId: null,
      isMobile: false
    };
  },
  validations() {
    return {
      deviceID: { required, uuidRegex } // Matches this.firstName
    };
  },
  created() {
    this.$v = useVuelidate(this.validations, this);
    this.isMobile = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

    this.loadData();
    bus.$on("net-reload", () => {
      this.loadData();
    });
  },
  methods: {
    async registerDevice(ev) {
      this.$log.debug("registering device");
      // register a device from a GET request
      if (ev) {
        ev.preventDefault();
      }

      //check to see if it's registered already
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      this.$axios
        .get(
          "/v1/accounts/" + store.state.accountid + "/devices/" + this.deviceID
        )
        .then(response => {
          this.$notify({
            title: "This device is already registered to your account.",
            type: "success"
          });
          router.push({
            name: "viewdevice",
            params: {
              account: this.$store.state.accountid,
              devId: this.deviceID
            }
          });
          return {};
        })
        .catch(err => {
          // 404 means we should try and register
          this.$log.debug(err.response.data.message);
          this.$axios
            .post("/v1/accounts/" + store.state.accountid + "/devices", {
              device_id: this.deviceID,
              network_id: this.$route.params.networkId
            })
            .then(response => {
              this.$notify({
                title: "Device Added",
                type: "success"
              });

              router.push({
                name: "viewdevice",
                params: {
                  account: this.$store.state.accountid,
                  devId: this.deviceID
                }
              });
            })

            .catch(err => {
              //malformed request
              this.$notify({
                title:
                  "Error while Adding Device " +
                  (err.response.data.message || ""),
                type: "error"
              });
            });
        });
    },

    setCallBack() {
      // get a scanner session
      this.callback =
        "https://blecon.app/scanner?callback=" +
        encodeURIComponent(
          "https://blecon.dev/dscb?si=" +
            this.scanId +
            "&ni=" +
            this.$route.params.networkId +
            "&nn=" +
            this.api.name +
            "&device_id={device_id}"
        );

      this.$log.debug("callback is " + this.callback);
    },

    startTimeout() {
      this.loopEnabled = true;
    },
    stopTimeout() {
      this.loopEnabled = false;
    },

    async startScannerSession() {
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      this.$axios
        .post("/v1/accounts/" + store.state.accountid + "/dscb", {
          network_id: this.$route.params.networkId
        })
        .then(response => {
          this.scanId = response.data.scan_id;
          this.setCallBack();
          // show modal
          this.$bvModal.show("modal-qr");
        })
        .catch(err => {
          this.$notify({
            title: "Error creating scan session",
            type: "error"
          });
        });
    },

    async loadData() {
      this.$store.commit("spin");

      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      this.$axios
        .get(
          "/v1/accounts/" +
            store.state.accountid +
            "/networks/" +
            this.$route.params.networkId
        )
        .catch(err => {
          router.push("/404");
        })
        .then(response => {
          if (!response) return; // `response` is undefined

          this.api = response.data;
          var udate = new Date(parseInt(this.api.last_updated) * 1000);
          this.api.last_updated = formatDistance(udate, new Date(), {
            addSuffix: true
          });
          var date = new Date(parseInt(this.api.created) * 1000);
          this.created_original = this.api.created;
          this.api.created = formatDistance(date, new Date(), {
            addSuffix: true
          });
          this.$store.commit("nospin");
        });
    }
  }
};
</script>
