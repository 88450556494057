<template>
  <div>
    <b-card class="mt-3">
      <template #header>
        <h5 class="mb-0">
          Devices
          <span class="text-muted small" style="float:right"
            >(Showing {{ results.length }} of {{ total }})</span
          >
        </h5>
      </template>
      <b-container fluid>
        <b-row class="mb-3">
          <!-- Device Connection Status Filter -->
          <b-col md="4">
            <b-form-group label="Device ID">
              <b-form-input
                v-model="filters.device_id"
                placeholder="Enter Device ID"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label="Created">
              <b-input-group>
                <b-form-datepicker
                  size="sm"
                  placeholder="Start Date"
                  v-model="dateFilters[0].start"
                  :date-format-options="{
                    year: '2-digit',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                ></b-form-datepicker>
                <b-input-group-append is-text>to</b-input-group-append>
                <b-form-datepicker
                  size="sm"
                  v-model="dateFilters[0].end"
                  placeholder="End Date"
                  :date-format-options="{
                    year: '2-digit',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                ></b-form-datepicker>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Last Spotted">
              <b-input-group>
                <b-form-datepicker
                  size="sm"
                  placeholder="Start Date"
                  :v-model="dateFilters[1].start"
                  :date-format-options="{
                    year: '2-digit',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                ></b-form-datepicker>
                <b-input-group-append is-text>to</b-input-group-append>
                <b-form-datepicker
                  size="sm"
                  :date-format-options="{
                    year: '2-digit',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  :v-model="dateFilters[1].end"
                  placeholder="End Date"
                ></b-form-datepicker>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Last Request">
              <b-input-group>
                <b-form-datepicker
                  size="sm"
                  placeholder="Start Date"
                  :v-model="dateFilters[2].start"
                  :date-format-options="{
                    year: '2-digit',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                ></b-form-datepicker>
                <b-input-group-append is-text>to</b-input-group-append>
                <b-form-datepicker
                  size="sm"
                  placeholder="End Date"
                  :v-model="dateFilters[2].end"
                  :date-format-options="{
                    year: '2-digit',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                ></b-form-datepicker>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="mb-3">
              <button @click="getResults" class="btn btn-primary mr-3 ">
                Search
              </button>
              <button @click="resetFilters" class="btn btn-secondary">
                Clear Filters
              </button>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-table
        striped
        hover
        responsive
        stacked="sm"
        small
        :fields="fields"
        :items="results"
        :no-local-sorting="true"
        :current-page="currentPage"
        :busy="isLoading"
        @sort-changed="onSortChanged"
      >
        <template #cell(device_id)="data">
          <div style="max-width: 25em; min-width: 10em;">
            <router-link
              style="font-family: monospace; font-size: 0.8em"
              :to="{
                name: 'viewdevice',
                params: { devId: data.item.device_id }
              }"
              >{{ data.item.device_id }}</router-link
            >

            <router-link
              style="font-family: monospace; font-size: 0.8em"
              :to="{
                name: 'net_home',
                query: { ex_device_id: data.item.device_id }
              }"
            >
              <i title="Filter by Device ID" class="ml-2 bx bx-search"></i>
            </router-link>
          </div>
        </template>

        <template v-slot:cell(last_seen)="data">
          <div style="white-space: nowrap; width: 10em; font-size: 0.8em">
            {{ formatDate(data.item.last_seen) }}
          </div>
        </template>

        <template v-slot:cell(created)="data">
          <div style=" white-space: nowrap; width: 10em; font-size: 0.8em">
            {{ formatDate(data.item.created) }}
          </div>
        </template>

        <template v-slot:cell(last_request)="data">
          <div style=" white-space: nowrap; width: 10em; font-size: 0.8em">
            {{ formatDate(data.item.last_request) }}
          </div>
        </template>
        <template v-slot:cell(last_customer_request_ts)="data">
          <div style=" white-space: nowrap; width: 10em; font-size: 0.8em">
            {{ formatDate(data.item.last_customer_request_ts) }}
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="this.total"
        @change="getResults"
      />
      <div v-if="isLoading" class="text-center">
        Loading...
      </div>
    </b-card>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/vue-demi"></script>
<script src="https://cdn.jsdelivr.net/npm/@vuelidate/core"></script>
<script src="https://cdn.jsdelivr.net/npm/@vuelidate/validators"></script>
<script>
//import shared from '@/components/Shared'
import router from "@/router";
import store from "@/store";

import { format, formatDistance, formatRelative, subDays } from "date-fns";

export default {
  data() {
    return {
      fields: [
        { key: "device_id", label: "Device ID", sortable: false },
        { key: "created", label: "Created", sortable: true },
        { key: "last_seen", label: "Last Spotted", sortable: true },
        {
          key: "last_customer_request_ts",
          label: "Last Request",
          sortable: true
        }
      ],
      results: [],
      currentPage: 1,
      sort: { field: "created", order: "desc" },
      filters: {
        device_id: null
      },
      dateFilters: [
        {
          field: "created",
          start: null,
          end: null
        },
        {
          field: "last_seen",
          start: null,
          end: null
        },
        {
          field: "last_request",
          start: null,
          end: null
        }
      ],

      currentPage: 1,
      total: 0,
      perPage: 10,
      isLoading: false,
      cursor: undefined,
      deviceCount: 0
    };
  },
  mounted() {
    // Load initial data when the component is mounted
    this.getResults();
  },

  watch: {
    // Load data whenever the page changes
    currentPage() {
      this.getResults();
    }
  },

  methods: {
    onReset(evt) {
      evt.preventDefault();
    },

    formatDate(value) {
      if (!value) return "-";
      const formattedDate = format(new Date(value), "yyyy-MM-dd HH:mm:ss");
      return formattedDate;
    },
    resetFilters() {
      this.filters = {
        device_id: null
      };
      this.dateFilters = [
        {
          field: "created",
          start: null,
          end: null
        },
        {
          field: "last_seen",
          start: null,
          end: null
        },
        {
          field: "last_updated",
          start: null,
          end: null
        }
      ];
      this.getResults();
    },
    onSortChanged({ sortBy, sortDesc }) {
      if (sortDesc == true) var sortType = "desc";
      else var sortType = "asc";

      this.sort = { field: sortBy, order: sortType };
      this.getResults();
    },

    async getResults() {
      this.isLoading = true;
      var accessToken = await this.$auth.getTokenSilently();

      const mustClauses = [];
      mustClauses.push({
        field: "network_id",
        value: this.$route.params.networkId
      });

      if (this.filters.device_id) {
        mustClauses.push({ field: "device_id", value: this.filters.device_id });
        //reset to first page of results
      }
      this.$log.debug(this.dateFilters);

      const searchBody = {
        sort: this.sort,
        pagination: {
          page_size: this.perPage,
          page: this.currentPage
        },
        fields: mustClauses,
        date_ranges: this.dateFilters
      };
      this.$axios
        .post(
          "/v1/accounts/" + store.state.accountid + "/device-explorer",
          searchBody
        )
        .then(response => {
          this.results = response.data.results;
          this.total = response.data.total;
          this.currentPage = response.data.page;
        });

      this.isLoading = false;
    }
  }
};
</script>
