<template>
  <div>
    <b-card class="mt-3">
      <template #header>
        <h3 class="mb-0">
          Active Devices
        </h3>
      </template>
      <b-card-text>
        <MyChart
          :stats="stats"
          :mode="'seen_devices'"
          v-if="stats.length > 0"
        />
      </b-card-text>
    </b-card>
    <b-card class="mt-3">
      <template #header>
        <h3 class="mb-0">
          Requests and Connections
        </h3>
      </template>
      <b-card-text>
        <MyChart :stats="stats" :mode="'requests'" v-if="stats.length > 0" />
      </b-card-text>
    </b-card>

    <b-card class="mt-3">
      <template #header>
        <h3 class="mb-0">
          Data Transfer
        </h3>
      </template>
      <b-card-text>
        <MyChart :stats="stats" :mode="'bytes'" v-if="stats.length > 0" />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
//import shared from '@/components/Shared'
import router from "@/router";
import store from "@/store";
import { bus } from "../../main";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers, url } from "@vuelidate/validators";
import MyChart from "@/components/Chart";
import { format, formatDistance, formatRelative, subDays } from "date-fns";

export default {
  components: {
    MyChart: MyChart
  },

  data() {
    return {
      stats: []
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      var accessToken = await this.$auth.getTokenSilently();
      var statsRequest = this.$axios.get(
        "/v1/accounts/" +
          store.state.accountid +
          "/stats?type=network&type_key=" +
          this.$route.params.networkId
      );
      Promise.all([statsRequest]).then(values => {
        const stats_response = values[0];
        this.stats = stats_response.data.stats;
      });
    }
  }
};
</script>
