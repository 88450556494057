var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[_c('b-breadcrumb',[_c('b-breadcrumb-item',{attrs:{"href":"#home"}},[_c('router-link',{attrs:{"to":{ name: 'root' }}},[_vm._v("Home ")])],1),_c('b-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'accountsettings' }}},[_vm._v("Account")])],1)],1),_c('b-modal',{attrs:{"id":"modal-4","title":"Edit Account","hide-footer":""},on:{"hidden":_vm.onReset}},[_c('b-form',{on:{"submit":_vm.updateAccount,"reset":_vm.onReset}},[_c('b-form-group',{attrs:{"size":"sm","id":"input-group-1","label":"Account Name","label-for":"input-1","description":""}},[_c('b-form-input',{attrs:{"size":"sm","name":"name","id":"input-1","type":"text"},model:{value:(_vm.accountForm.name),callback:function ($$v) {_vm.$set(_vm.accountForm, "name", $$v)},expression:"accountForm.name"}})],1),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"type":"reset"}},[_vm._v("Cancel")]),_vm._v("   "),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Save")])],1)],1)],1),_c('div',[_c('b-card-group',{attrs:{"deck":""}},[_c('b-card',{staticClass:"mb-2",attrs:{"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticClass:"mb-0"},[_vm._v(" Account "),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-4",modifiers:{"modal-4":true}}],staticClass:"float-right",attrs:{"size":"sm","variant":"primary","title":_vm.$store.state.accountLevel > 20
                  ? 'Insufficent permissions'
                  : '',"disabled":_vm.$store.state.accountLevel > 20}},[_vm._v("Edit ")])],1)]},proxy:true}])},[_c('b-card-text',[_c('h2',[_vm._v(_vm._s(_vm.account.name || "Not Set"))]),_c('p',{staticClass:"text-muted"},[_vm._v("Account ID: "+_vm._s(_vm.account.id || "Not Set"))]),_c('p',{staticClass:"text-muted"},[_vm._v("Your role: "+_vm._s(_vm.accountLevel))]),_c('p',{staticClass:"text-muted"},[(_vm.account.subscription_status.status == 'active')?_c('span',[_vm._v(" Manage your subscription and view invoices at the "),_c('a',{attrs:{"href":"https://billing.blecon.net/p/login/bIYg1Jh0j8ok6Gs6oo"}},[_vm._v("Blecon Billing Portal")]),_vm._v(". ")]):_vm._e()])])],1),(_vm.account.default)?_c('p',[_vm._v(" This is your default account and you are the owner. ")]):_vm._e()],1)],1),_c('b-nav',{staticClass:"mt-3",attrs:{"tabs":""}},[_c('b-nav-item',{attrs:{"exact":"","to":{
        name: 'account_members'
      }}},[_vm._v("Members ")]),_c('b-nav-item',{attrs:{"to":{
        name: 'account_invites'
      }}},[_vm._v("Invites ")]),_c('b-nav-item',{attrs:{"to":{
        name: 'account_apitokens'
      }}},[_vm._v(" API Tokens ")]),_c('b-nav-item',{attrs:{"to":{
        name: 'account_logs'
      }}},[_vm._v(" Account Log ")])],1),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }